iframe {
  display: none;
}

*{
  padding: 0;
  margin: 0;
}

html,body{
  overflow: hidden;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  margin: 0;
}

@media screen and (max-width:720px) {
  html,body{
    overflow-y: auto;
  }
}

html{
  --status-bar-height: 0px; 
  --top-window-height: 0px; 
  --window-left: 0px;
   --window-right: 0px; 
   --window-margin: 0px; 
   --window-top: calc(var(--top-window-height)
  + 0px); 
  --window-bottom: calc(50px + env(safe-area-inset-bottom));
}