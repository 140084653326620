.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: .12rem;
  color: black;
  background: #262B47;
  .main{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url(../../assets/bg.png) no-repeat center;
    background-size: cover;
    border-radius: 0 0 .5rem .5rem;
    .logo{
      margin: 0;
      img{
        height: 1.17rem;
      }
    }
    .des{
      font-size: .2rem;
      color: rgba(0, 0, 0, 0.73);
      margin: .2rem 0 .7rem;
      font-style: italic;
    }
    .btn{
      button{
        font-size: .24rem;
        line-height: .32rem;
        padding: .17rem 1.05rem;
        border: none;
        background: #647AF5;
        color: #fff;
        border-radius: .08rem;
        text-transform: uppercase;
      }
    }
  }
  .footer{
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 3rem;
    .cooperate_list{
      display: flex;
      justify-content: space-between;
      .item{
        img{
          height: .47rem;
        }
      }
    }
  }
}


@media screen and (max-width:720px) {
  .home{
    .main{
      background-image: url(../../assets/m_bg.png);
      .logo{
        img{
          height: .59rem;
        }
      }
      .des{
        font-size: .12rem;
      }
      .btn{
        button{
          padding: .15rem .2rem;
          font-size: .18rem;
          line-height: 1;
        }
      }
    }
    .footer{
      padding: .4rem .6rem .35rem;
      .cooperate_list{
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        grid-row-gap: .36rem;
        justify-content: space-between;
        .item{
          img{
            height: .22rem;
          }
          &:nth-child(2n){
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}